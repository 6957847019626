<template>
  <loader>
    <div
      class="index"
    >
      <router-view />
    </div>
  </loader>
</template>

<script>
import Loader from '@/views/Layouts/Loader.vue'

export default {
  name: 'FullLayout',

  components: {
    Loader,
  },
}
</script>

<style scoped>
.index {
  background-color: #fff;
}
</style>
